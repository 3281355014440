import request from '../request'

//客户列表
export const client_index_request = p => {
    return request({
        method:'GET',
        url:'client/index',
        params: p
    })
}

//添加客户
export const client_add_request = d => {
    return request({
        method:'POST',
        url:'client/add',
        data: {
            name: d.name,
            type: d.type,
            address: d.address,
            contacts: d.contacts,
            phone: d.phone,
            note: d.note
        }
    })
}

//删除客户
export const client_del_request = id => {
    return request({
        method:'DELETE',
        url:'client/del',
        data: {
            id: id
        }
    })
}

//修改客户
export const client_edit_request = d => {
    return request({
        method:'PUT',
        url:'client/edit',
        data: {
            id: d.id,
            name: d.name,
            type: d.type,
            address: d.address,
            contacts: d.contacts,
            phone: d.phone,
            note: d.note
        }
    })
}

//获取客户详情
export const client_details_request = id => {
    return request({
        method:'GET',
        url:'client/details',
        params: {
            id
        }
    })
}