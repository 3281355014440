<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <van-field
                        v-model="details.name"
                        name="name"
                        label="企业名称"
                        placeholder="企业名称"
                        :rules="[{ required: true, message: '请填写企业名称' }]"
                    />
                    <select-component
                        name="type"
                        lable="企业类型"
                        :farr="all_type"
                        :fvalue_name="details.type_name"
                        @clk="confirm_type"
                    ></select-component>
                    <van-field
                        type="textarea"
                        v-model="details.address"
                        name="address"
                        label="企业地址"
                        placeholder="客户地址"
                        :rules="[{ required: true, message: '请填写企业地址' }]"
                    />
                    <van-field
                        v-model="details.contacts"
                        name="contacts"
                        label="联系人"
                        placeholder="联系人"
                        :rules="[{ required: true, message: '请填写联系人' }]"
                    />
                    <number-keyboard-component
                        name="phone"
                        label="联系电话"
                        placeholder="请输入联系电话"
                        :fvalue="details.phone"
                        @on_input="confirm_phone"
                    ></number-keyboard-component>
                    <van-field
                        type="textarea"
                        v-model="details.note"
                        name="note"
                        label="备注"
                        placeholder="备注"
                    />
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="企业名称" :value="details.name" />
                <van-cell title="企业类型" :value="details.type_name" />
                <van-cell title="企业地址" :value="details.address" />
                <van-cell title="联系人" :value="details.contacts" />
                <van-cell title="联系电话" :value="details.phone" />
                <van-cell title="备注" :value="details.note" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="this.$_has('修改客户')" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="this.$_has('删除客户')" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { client_details_request,client_del_request,client_edit_request,client_add_request } from '@/network/WareHouse/client'

export default {
    name:'ClientDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                name: '',
                type: '',
                address: '',
                contacts: '',
                phone: '',
                note: ''
            },
            edit: false,
            all_type: [
                { id:0,name:'客户' },
                { id:1,name:'供应商' },
                { id:2,name:'我方企业' },
                { id:3,name:'客户+供应商' },
            ]
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改企业' : '新增企业') : '企业详情'
        }
    },
    methods:{
        get_client_details() {
            this.$store.commit('true_loading')
            client_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                client_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        confirm_type(e) {
            this.details.type = e.id
        },
        confirm_phone(e) {
            this.details.phone = e*1
        },
        back_details() {
            this.get_client_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                name: this.details.name,
                type: this.details.type,
                address: this.details.address,
                contacts: this.details.contacts,
                phone: this.details.phone,
                note: this.details.note
            }
            client_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_client_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                name: this.details.name,
                type: this.details.type,
                address: this.details.address,
                contacts: this.details.contacts,
                phone: this.details.phone,
                note: this.details.note
            }
            client_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_client_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{},
    props:{},
    created(){
        this.id = this.$route.query.id;
        if(this.id) {
            this.get_client_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>